<template>
    <div>
        <b-card no-body class="mb-0">
            <b-overlay :show="loading" rounded="sm" no-fade>
      <div class="m-2">
                    <b-row>
                        <!-- Per Page -->
                        <b-col cols="12" md="7" class="d-flex align-items-center justify-content-start mb-1 mb-md-0"> </b-col>

                        <!-- Search -->
                        <b-col cols="12" md="5">
                            <div class="d-flex align-items-center justify-content-end mt-2">
                                <b-button variant="primary" @click="submitForm()">
                                    <span class="text-nowrap">Save</span>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>


      <b-table

      striped
      hover
      class="position-relative"
      responsive
      :small="true"
      head-variant="dark"
      show-empty
      :items="settings" 
      :fields="fields">
        <template #cell(description)="data">
          <span>{{ data.item.description }}</span>
        </template>

        <template #cell()="data">
          <b-form-checkbox switch size="sm" class="ml-5" v-model="data.item.choice"> </b-form-checkbox>
        </template>
      </b-table>


     
            </b-overlay>
            </b-card>
            </div>

</template>

<script>
import store from '@/store';
import Ripple from 'vue-ripple-directive';
import settingsStoreModule from '../../settingsStoreModule';
import { onUnmounted } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import { BTable } from 'bootstrap-vue';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTable,
  },
  directives: {
    Ripple,
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';

    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },
  props: ['getRoles', 'loading'],
  data() {
    return {

      userData: JSON.parse(localStorage.getItem("userData")),
      fields: [
        { key: 'description', sortable: false, class: 'text-center', thClass: 'text-white' },
        { key: 'choice', sortable: false, class: 'text-center', thClass: 'text-white' },
      ],

      settings: [

    
      ],
     
    };
  },
  methods: {
    submitForm() {
          store.dispatch('settings/updateDefaultTicketVisibility', {personal_settings : this.settings}).then((response) => {
            if (response.status == 200) {

              this.userData.personal_settings = this.settings

              localStorage.setItem("userData", JSON.stringify(this.userData));
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Settings updated successfully',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getDefaultTicketVisibility();
            }
          }); // dispatch store action
    },
    getDefaultTicketVisibility() {
      store
        .dispatch('settings/getDefaultTicketVisibility')
        .then((res) => {

          this.settings=res.data


         
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
  },
  computed: {
    
  },
  created() {

    this.getDefaultTicketVisibility()


  },
};
</script>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
  width: 100%;
}
</style>
